<template>
  <div class="product-box-wide__segment-image">
    <component
      :is="getPersonalizedBannerLink() ? 'a' : 'div'"
      v-if="productBoxData.personalPreferenceMessageKey"
      class="product-box-wide__personalization-banner | personalization__banner"
      :class="`personalization__banner--type_${getPersonalPreferenceRank()}`"
      :href="getPersonalizedBannerLink()"
    >
      <div class="product-box-wide__personalization-banner-icon | personalization__banner-icon" />
      <div
        class="product-box-wide__personalization-banner-content | personalization__banner-content"
        v-html="$t(productBoxData.personalPreferenceMessageKey)"
      />
    </component>
    <div
      class="product-box-wide__product-flags"
      v-if="productBoxData.flags"
    >
      <flags
        :flags="productBoxData.flags"
        :limit="3"
        :award-limit="1"
        :white-list="settings.flags.whitelistImage"
      />
    </div>
    <component
      :is="productBoxData.linked ? 'a' : 'div'"
      :href="productBoxData.linked ? productBoxData.url : null"
      class="product-box-wide__image-wrapper"
      :class="{ 'product-box-wide__image-wrapper--bottle': isBoxTypeVertical }"
    >
      <div
        class="lazy-load-image product-box-wide__image"
      >
        <image-element
          img-class="lazy-load-image__image"
          :src="imgSrc"
          :title="productBoxData.images[1].title"
          :alt="productBoxData.images[1].alt"
          :webp="true"
        />
      </div>
    </component>
  </div>
</template>
<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import type ProductBoxData from '../../../bps/types/ProductBoxData';
  import Flags from '../flags/flags.vue';
  import LazyLoad from '../../../bps/components/lazyLoad/lazyLoad.vue';
  import ImageElement from '../../../bps/components/imageElement/imageElement.vue';
  import {ProductBoxWideSettings} from './productBoxWideSettings';

  @Options({
    name: 'product-box-wide-segment-image',
    components: {
      Flags,
      LazyLoad,
      ImageElement
    }
  })
  export default class ProductBoxWideSegmentImage extends Vue {
    @Prop()
    productBoxData: ProductBoxData;
    @Prop()
    settings: ProductBoxWideSettings;
    @Prop()
    imgSrc: string[];
    @Prop()
    isBoxTypeVertical: boolean;
    @Prop()
    isBottle: boolean;

    getPersonalizedBannerLink(): string|null {
      if (this.productBoxData.personalPreferenceMessageKey === 'personalization.product.banner.rank2') {
        return this.productBoxData.url + '#reviewForm';
      }

      return null;
    }

    getPersonalPreferenceRank() {
      return this.productBoxData.personalPreferenceMessageKey.split('.').pop();
    }
  }
</script>
