import JsComponent from '../../../bps/components/jsComponent/jsComponent.js';
import debounce from 'lodash/debounce';
import pigeon from '@/bps/utilities/js/pigeon/pigeon';

class Header extends JsComponent {
  constructor(mainElement, name = 'header') {
    super(mainElement, name);
    this.createClassNames(
      '__wrapper',
      '__sticky',
      '__sticky--active',
      '__search-icon',
      '--search-active',
      '__mobile-search',
      '__mobile-search--active'
    );

    if (document.querySelector('.native-app')) {
      return;
    }

    this.headerHeight = Math.round(document.querySelector('.header').getBoundingClientRect().height);
    this.stickyEnabled = this.mainElement.dataset.stickyEnabled ? JSON.parse(this.mainElement.dataset.stickyEnabled) : true;

    if (this.stickyEnabled) {
      this.layoutElement = document.querySelector('.page-main');
      this.stickyElement = this.mainElement.querySelector(`.${this.classNames.sticky}`);
      this.stickyIsActive = false;
      if (this.stickyElement) {
        this.pageMainElementTop = Math.round(this.stickyElement.getBoundingClientRect().height);
      }
      this.windowResizeHandler();
      this.headerStickyToggler();
      this.windowResizeHandlerDobunced = debounce(this.windowResizeHandler.bind(this), 100);
      window.addEventListener('resize', this.windowResizeHandlerDobunced);
    }

    this.bindEvents();
    this.isScrollHandlerActive = true;
  }

  getWindowScrollPos() {
    return window.pageYOffset;
  }

  bindEvents() {
    if (this.stickyEnabled) {
      const scrollHandler = this.windowScrollHandler.bind(this);
      window.addEventListener('scroll', debounce(scrollHandler, 25, {
        maxWait: 50
      }));
    }

    pigeon.subscribe('header:setHeaderHeight', () => {
      this.setHeaderHeight();
    });
  }

  windowResizeHandler() {
    if (this.stickyElement) {
      this.setHeaderHeight();
    }
  }

  windowScrollHandler() {
    this.headerStickyToggler();
    if (this.isSearchActive() && this.isScrollHandlerActive) {
      if (this.isSuggestActive()) {
        this.mainElement.querySelector('.search-autosuggest__input input').blur();
      } else {
        this.toggleSearch();
      }
    }
  }

  headerStickyToggler() {
    const windowScrollPos = this.getWindowScrollPos();

    if (windowScrollPos > this.pageMainElementTop && !this.stickyIsActive) {
      this.activateStickyPos();
    } else if (windowScrollPos <= this.pageMainElementTop && this.stickyIsActive) {
      this.deactivateStickyPos();
    }
  }

  activateStickyPos() {
    this.stickyIsActive = true;
    this.stickyElement.classList.add(this.classNames.stickyActive);
    this.layoutElement.style.paddingTop = this.pageMainElementTop + 'px';
    this.layoutElement.classList.add('page-main--sticky-active');
    document.body.classList.add('page-main--sticky-active');
  }

  deactivateStickyPos() {
    this.stickyIsActive = false;
    this.stickyElement.classList.remove(this.classNames.stickyActive);
    this.layoutElement.style.paddingTop = '0';
    this.layoutElement.classList.remove('page-main--sticky-active');
    document.body.classList.remove('page-main--sticky-active');
  }

  toggleSearch() {
    const mobileSearchHeader = document.querySelector(`.${this.classNames.mobileSearch}`);
    mobileSearchHeader.classList.toggle(this.classNames.mobileSearchActive);
    pigeon.publish('header:setHeaderHeight');

    if (this.isSearchActive()) {

      // In some cases mobile browsers scroll to the focused input field,
      // which would close our search. Deactivate our behavior for a short time to prevent this.
      this.isScrollHandlerActive = false;
      setTimeout(() => {
        this.isScrollHandlerActive = true;
      }, 1000);

      // Put focus on search field
      this.mainElement.querySelector('.input__field').focus();
    }
  }

  isSearchActive() {
    return this.mainElement.classList.contains(this.classNames.mobileSearchActive);
  }

  isSuggestActive() {
    return !!this.mainElement.querySelector('.search-autosuggest__suggestions-flyout');
  }

  setHeaderHeight() {
    const stickyHeight = Math.round(this.stickyElement.getBoundingClientRect().height);
    this.pageMainElementTop = stickyHeight;

    const newHeaderHeight = document.querySelector('.header').getBoundingClientRect().height;
    if (newHeaderHeight > 0) {
      this.headerHeight = newHeaderHeight;
    }

    if (this.stickyIsActive) {
      this.layoutElement.style.paddingTop = this.headerHeight + 'px';
    }

    this.layoutElement.style.setProperty('--header-height', `${this.headerHeight}px`);
    this.layoutElement.style.setProperty('--header-sticky-height', `${stickyHeight}px`);
  }
}

export default Header;
