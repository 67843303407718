import {productBoxSettings, ProductBoxSettings} from '../../../bps/components/productBox/productBoxSettings';

productBoxSettings.flags.src.awardInSetImage = '/assets/enzo/frontend-core/staticImages/awardInSet.svg';
productBoxSettings.flags.whitelist = ['stoerer', 'new', 'newVintage', 'free', 'nonAlcoholic', 'awardInSet', 'award', 'bio', 'vegan'];
productBoxSettings.flags.whitelistInSet = ['bottleSize'];
productBoxSettings.flags.whitelistTag = ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'bottleSize'];
productBoxSettings.rating.type = 'text';

productBoxSettings.price.showTax = true;
productBoxSettings.slider = {
  mobile: {
    offset: 0,
    centeredSlides: false
  },
  tablet: {
    offset: 0,
    centeredSlides: false
  },
  desktop: {
    offset: 0,
    centeredSlides: false
  }
};

export {productBoxSettings, ProductBoxSettings};
